<template>
  <div class="coursewareDetails">
    <template v-if="$route.name=='coursewareDetails'">
      <div class="search_sList">
        <div class="top_opera">
          <el-form
            :inline="true"
            :model="formInline"
            class="demo-form-inline"
            label-width="80px"
          >
            <el-form-item label="课件标题">
              <el-input
                v-model="formInline.name"
                placeholder="课件标题"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="所属课程">
              <el-input
                v-model="formInline.courseName"
                placeholder="所属课程"
                clearable
              ></el-input>
            </el-form-item>
          </el-form>
          <div class="btn_area">
            <el-button
              class="mainBtn"
              @click="onSubmit"
              plain
              v-preventReClick
              >搜索</el-button
            >
            <el-button type="info" @click="resetEvent" plain>重置</el-button>
          </div>
        </div>
      </div>
      <div class="table_sList">
        <div class="opera_btnArea">
          <el-button class="mainBtn" @click="addEvent" plain>添加课件</el-button>
        </div>
        <el-table
          :data="tableData"
          stripe
          border
          style="width: 100%"
          align="center"
        >
          <el-table-column
            label="序号"
            align="center"
            width="55"
          >
            <template slot-scope="scope">
              {{
                (formInline.page - 1) * formInline.pageSize + scope.$index + 1
              }}
            </template>
          </el-table-column>
          <el-table-column
            prop="coursewareName"
            label="课件标题"
            align="center"
          >
            <template slot-scope="scope">
              <el-tooltip
                :content="scope.row.coursewareName"
                placement="top"
                effect="light"
                popper-class="pubToop"
              >
                <div class="ellipsis">{{ scope.row.coursewareName || '/' }}</div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column
            prop="courseName"
            label="课程"
            align="center"
          >
            <template slot-scope="scope">
              <el-tooltip
                :content="scope.row.courseName"
                placement="top"
                effect="light"
                popper-class="pubToop"
              >
                <div class="ellipsis">{{ scope.row.courseName || '/' }}</div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column
            prop="courseClassifyName"
            label="课程分类"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="years"
            label="年份"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="lessonNum"
            label="总课时数"
            align="center"
          >
          </el-table-column>
          <!-- <el-table-column
            prop="type"
            label="发布课时"
            align="center"
          >
          </el-table-column> -->
          <el-table-column
            prop="totalTime"
            label="总时长"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="form"
            label="课件形式"
            align="center"
            :formatter="regForm"
          >
          </el-table-column>
          <el-table-column
            prop="teacherName"
            label="教师"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="companyName"
            label="授课学校"
            align="center"
          >
            <template slot-scope="scope">
              <el-tooltip
                :content="scope.row.companyName"
                placement="top"
                effect="light"
                popper-class="pubToop"
              >
                <div class="ellipsis">{{ scope.row.companyName || '/' }}</div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column
            fixed="right"
            label="操作"
            width="120"
            align="center">
            <template slot-scope="scope">
              <el-button type="text" @click="jumpView(scope.row)">预览</el-button>
              <!-- <el-button type="text" @click="editEvent('edit', scope.row)">编辑</el-button> -->
              <el-button type="text" @click="deleteEvent(scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <Pagination
          @changePage="changePage"
          :childMsg="childMsg"
        ></Pagination>
      </div>
      <Keydown @keydownEvent='handleKeydown'></Keydown>
      <dialogDiy :isShow="isShowSort" :dialogDiyData="dialogSortDiyData"
      @dialogEvent="isShowSort = false">
        <div slot="dialogContent" class="class_area">
          <el-form :inline="true" :model="sortForm" class="demo-form-inline" label-width="100px">
            <el-form-item label="排序" prop="name">
              <el-input
                v-model="sortForm[0].sort"
                placeholder="排序"
                clearable
              ></el-input>
            </el-form-item>
          </el-form>
          <div class="btn_area">
            <el-button @click="isShowSort = false">取消</el-button>
            <el-button type="primary" @click="enterSortEvent">确认</el-button>
          </div>
        </div>
      </dialogDiy>

      <dialogDiy
        :isShow="isShowDelCourse"
        :dialogDiyData="dialogDelCoDiyData"
        @dialogEvent="isShowDelCourse = false"
      >
        <div slot="dialogContent" class="cc_opera">
          <img class="errIcon" src="../../../assets/img/warn.png" alt="" />
          <p>确定要删除该课件吗？</p>
          <div class="btn_area">
            <el-button @click="isShowDelCourse = false">关闭</el-button>
            <el-button class="mainBtn" @click="doEvent">确定</el-button>
          </div>
        </div>
      </dialogDiy>

      <dialogDiyNew :isShow="isShowAdd" :dialogDiyData="dialogAddDiyData"
      @dialogEvent="isShowAdd = false">
        <div slot="dialogContent" class="course_area">
          <div class="man-title" v-if="selectData.length != 0">已添加课件：</div>
          <div class="select-area">
            <div class="manage" v-for="(item, index) in selectData" :key="index" @mousemove="showEvent(index, 'over')" @mouseleave="showEvent(index, 'leave')">
              <img class="manClose" v-show="selectData[index].isShow" src="../../../assets/img/man-close.png" alt="" @click="byDeleteEvent(index)">
              <span>{{item.coursewareName || '/'}}</span>
            </div>
          </div>
          <div class="search_sList">
            <div class="top_opera">
              <el-form
                :inline="true"
                :model="wareForm"
                class="demo-form-inline"
                label-width="80px"
              >
                <el-form-item label="课件标题">
                  <el-input
                    v-model="wareForm.title"
                    placeholder="课件标题"
                    clearable
                  ></el-input>
                </el-form-item>
                <el-form-item label="所属课程">
                  <el-input
                    v-model="wareForm.courseName"
                    placeholder="所属课程"
                    clearable
                  ></el-input>
                </el-form-item>
              </el-form>
              <div class="btn_search_area">
                <el-button
                  class="mainBtn"
                  @click="onWareSubmit"
                  plain
                  v-preventReClick
                  >搜索</el-button
                >
                <el-button type="info" @click="resetWareEvent" plain>重置</el-button>
              </div>
            </div>
          </div>
          <div class="table_list_new">
            <el-table
              :data="tableAddData"
              stripe
              border
              style="width: 100%"
              align="center"
              max-height="400"
            >
              <el-table-column
                prop="type"
                label="序号"
                align="center"
                width="55"
              >
                <template slot-scope="scope">
                  {{
                    (wareForm.page - 1) * wareForm.pageSize + scope.$index + 1
                  }}
                </template>
              </el-table-column>
              <el-table-column
                prop="coursewareName"
                label="课件标题"
                align="center"
              >
                <template slot-scope="scope">
                  <el-tooltip
                    :content="scope.row.coursewareName"
                    placement="top"
                    effect="light"
                    popper-class="pubToop"
                  >
                    <div class="ellipsis">{{ scope.row.coursewareName || '/' }}</div>
                  </el-tooltip>
                </template>
              </el-table-column>
              <el-table-column
                prop="courseName"
                label="课程"
                align="center"
              >
                <template slot-scope="scope">
                  <el-tooltip
                    :content="scope.row.courseName"
                    placement="top"
                    effect="light"
                    popper-class="pubToop"
                  >
                    <div class="ellipsis">{{ scope.row.courseName || '/' }}</div>
                  </el-tooltip>
                </template>
              </el-table-column>
              <el-table-column
                prop="courseClassifyName"
                label="课程分类"
                align="center"
                width="80"
              >
              </el-table-column>
              <el-table-column
                prop="year"
                label="年份"
                align="center"
                width="60"
              >
              </el-table-column>
              <el-table-column
                prop="lessonNum"
                label="总课时数"
                align="center"
                width="70"
              >
              </el-table-column>
              <!-- <el-table-column
                prop="type"
                label="发布课时"
                align="center"
              >
              </el-table-column> -->
              <el-table-column
                prop="totalTime"
                label="总时长"
                align="center"
                width="70"
              >
              </el-table-column>
              <el-table-column
                prop="form"
                label="课件形式"
                align="center"
                :formatter="regForm"
              >
              </el-table-column>
              <el-table-column
                prop="teacherName"
                label="教师"
                align="center"
              >
              </el-table-column>
              <el-table-column
                prop="companyName"
                label="授课学校"
                align="center"
              >
                <template slot-scope="scope">
                  <el-tooltip
                    :content="scope.row.companyName"
                    placement="top"
                    effect="light"
                    popper-class="pubToop"
                  >
                    <div class="ellipsis">{{ scope.row.companyName || '/' }}</div>
                  </el-tooltip>
                </template>
              </el-table-column>
              <el-table-column
                fixed="right"
                label="操作"
                width="100"
                align="center">
                <template slot-scope="scope">
                  <el-button :disabled="checkAdd(scope.row)" type="text" @click="addSpecial(scope.row)">{{checkAdd(scope.row) ? '已添加' : '添加'}}</el-button>
                </template>
              </el-table-column>
            </el-table>
            <Pagination
              @changePage="changeCoursePage"
              :childMsg="childCourseMsg"
            ></Pagination>
          </div>
          <div class="btn_area">
            <el-button @click="isShowAdd = false">取消</el-button>
            <el-button type="primary" @click="enterEvent">确认</el-button>
          </div>
        </div>
      </dialogDiyNew>
    </template>
    <template v-else>
      <router-view></router-view>
    </template>
  </div>
</template>

<script>
import Pagination from '../../Pagination'
import dialogDiy from '../../commonModule/dialogDiy'
import dialogDiyNew from '../../commonModule/dialogDiyNew'
export default {
  name: 'coursewareDetails',
  components: {
    Pagination,
    dialogDiy,
    dialogDiyNew
  },
  data() {
    return {
      formInline: {
        courseName: null,
        name: null,
        page: 1,
        pageSize: 10,
        subjectId: null
      },
      tableData: [],
      childMsg: {
        current: 1,
        size: 10,
        total: 1,
      },
      isShowSort: false,
      sortForm: [{
        aidClassId: null,
        companyName: null,
        courseId: null,
        courseName: null,
        coursewareId: null,
        coursewareImgUrl: null,
        coursewareName: null,
        id: null,
        lessonNum: null,
        sort: null,
        totalTime: null,
        years: null
      }],
      dialogSortDiyData: {
        title: '编辑',
        sizeStyle: {width: '400px',height: '234px'}
      },
      isShowDelCourse: false,
      dialogDelCoDiyData: {
        title: null,
        sizeStyle: {width: '400px',height: '234px'}
      },
      rowData: null,
      isShowAdd: false,
      dialogAddDiyData: {
        title: '添加课件',
        sizeStyle: {width: '1000px',height: '780px'}
      },
      wareForm: {
        courseName: null,
        name: null,
        page: 1,
        pageSize: 10,
        subjectId: null,
        title: null
      },
      tableAddData: [],
      childCourseMsg: {
        current: 1,
        size: 10,
        total: 1,
      },
      selectData: [],
      courseIds: []
    }
  },
  created() {
    if(this.$route.name != 'coursewareDetails') return;
    this.getCoursewareList()
  },
  methods: {
    handleKeydown(event) {
      if (!this.isShowSort&&!this.isShowDelCourse&&!this.isShowAdd) {
        this.onSubmit()
      }
    },
    getAidCourseIds() {
      this.$request.getAidCourseIds({aidClassId: this.$route.query.aidClassId}).then(res => {
        if(res.data.code == 0) {
          this.courseIds = Array.from(new Set(res.data.data));
        }
      })
    },
    // 搜索
    onSubmit() {
      this.formInline.page = 1;
      this.formInline.pageSize = 10;
      this.getCoursewareList();
    },
    // 重置
    resetEvent() {
      this.formInline.courseName = null
      this.formInline.name = null
      this.formInline.page = 1
      this.formInline.pageSize = 10
      this.formInline.subjectId = null
    },
    // 搜索
    onWareSubmit() {
      this.wareForm.page = 1;
      this.wareForm.pageSize = 10;
      this.getAddCoursewareList();
    },
    // 重置
    resetWareEvent() {
      this.wareForm.courseName = null
      this.wareForm.name = null
      this.wareForm.page = 1
      this.wareForm.pageSize = 10
      this.wareForm.subjectId = null
      this.wareForm.title = null
    },
    regForm(row) {
      let val = String(row.form)
      let label;
      switch(val) {
        case '0':
          label = '一般课程';
          break;
        case '1':
          label = '精品课程';
          break;
        case '2':
          label = '直播讲座';
          break;
        case '3':
          label = '实验课';
          break;
        case '4':
          label = '说课';
          break;
        case '5':
          label = '其他';
          break;
      }
      return label;
    },
    // 获取表格数据
    getCoursewareList() {
      let obj = this.formInline;
      this.$request.coursewareList(obj, this.$route.query.aidClassId).then(res => {
        if (res.data.code == 0) {
          let { records, current, size, total } = res.data.data;
          this.tableData = records;
          this.childMsg = {
            current,
            size,
            total
          };
        }
      });
    },
    changePage(val) {
      this.childMsg = val;
      this.formInline.page = val.current;
      this.formInline.pageSize = val.size;
      this.getCoursewareList();
    },
    // 获取表格数据--添加课件
    getAddCoursewareList() {
      let obj = this.wareForm;
      this.$request.coursewareChangeList(obj).then(res => {
        if (res.data.code == 0) {
          let { records, current, size, total } = res.data.data;
          this.tableAddData = records;
          this.childCourseMsg = {
            current,
            size,
            total
          };
        }
      });
    },
    changeCoursePage(val) {
      this.childCourseMsg = val;
      this.wareForm.page = val.current;
      this.wareForm.pageSize = val.size;
      this.getAddCoursewareList();
    },
    addEvent() {
      this.isShowAdd = !this.isShowAdd
      this.getAidCourseIds()
      this.getAddCoursewareList()
    },
    addSpecial(row) {
      this.selectData.push({
        "aidClassId": this.$route.query.aidClassId,
        "companyName": row.companyName,
        "courseId": row.subjectId,
        "courseName": row.courseName,
        "coursewareId": row.id,
        "imgUrl": row.imgUrl,
        "coursewareName": row.coursewareName,
        "form": row.form,
        "courseClassifyName": row.courseClassifyName,
        // "id": 0,
        "lessonNum": row.lessonNum,
        // "sort": 0,
        "teacherName": row.teacherName,
        "totalTime": row.totalTime,
        "years": row.year
      });
      this.dialogAddDiyData.sizeStyle.height = '900px'
    },
    checkAdd(row) {
      let index = this.selectData.findIndex(item => item.coursewareId == row.id)
      let isIndex = this.courseIds.findIndex(item => item == row.id)
      if(index > -1 || isIndex > -1)
        return true;
      else
        return false;
    },
    byDeleteEvent(index) {
      this.selectData.splice(index, 1);
    },
    showEvent(index, type) {
      if(type == 'over')
        this.$set(this.selectData[index], 'isShow', true)
      else
        this.$set(this.selectData[index], 'isShow', false)
      this.$forceUpdate()
    },
    // 编辑
    editEvent(row) {
      this.isShowSort = !this.isShowSort;
      this.rowData = row;
    },
    enterEvent() {
      let dtos = this.selectData;
      if(dtos.length == 0) {
        this.Warn('请选择课件');
        return;
      }
      this.$request.addCourseWare(dtos).then(res => {
        if(res.data.code == 0) {
          this.Success('操作成功');
          this.getCoursewareList();
          this.isShowAdd = !this.isShowAdd;
          this.clearAddEvent();
        }
      })
    },
    // 删除
    deleteEvent(row) {
      this.rowData = row;
      this.isShowDelCourse = !this.isShowDelCourse;
    },
    doEvent() {
      this.$request.deleteCourseWare({aidCourseWareId: this.rowData.id}).then(res => {
        if(res.data.code == 0) {
          this.Success(res.data.msg);
          this.getCoursewareList();
          this.isShowDelCourse = !this.isShowDelCourse;
        }
      })
    },
    enterSortEvent() {
      // let dtos = this.sortForm;
      // dtos[0].aidClassId = this.$route.query.aidClassId
      // dtos[0].companyName = this.rowData.companyName
      // dtos[0].courseId = this.rowData.subjectId
      // dtos[0].courseName = this.rowData.courseName
      // dtos[0].coursewareId = this.rowData.id
      // dtos[0].coursewareImgUrl = this.rowData.
      // dtos[0].coursewareName = this.rowData.
      // dtos[0].id = this.rowData.
      // dtos[0].lessonNum = this.rowData.
      // dtos[0].sort = this.rowData.
      // dtos[0].totalTime = this.rowData.
      // dtos[0].years = this.rowData.
      // this.$request.addCourseWare(dtos).then(res => {
      //   if(res.data.code == 0) {
      //     this.Success('操作成功');
      //     this.getCoursewareList();
      //     this.isShowAdd = !this.isShowAdd;
      //   }
      // })
    },
    // 预览
    jumpView(row) {
      this.$router.push({name: 'viewCourseware', query: {
        courseId: row.coursewareId
      }})
    },
    clearAddEvent() {
      this.dialogAddDiyData.sizeStyle.height = '780px'
      this.wareForm.page = 1
      this.wareForm.pageSize = 10
      this.selectData = []
      this.wareForm.courseName = null
      this.wareForm.name = null
      this.wareForm.subjectId = null
    }
  }
}
</script>

<style lang='less'>
  .coursewareDetails {
    .man-title {
      font-size: 16px;
      color: #333;
      margin-top: 10px;
      margin-bottom: 10px;
    }
    .select-area {
      display: flex;
      flex-wrap: wrap;
      .manage {
        position: relative;
        height: 30px;
        line-height: 30px;
        font-size: 14px;
        color: #1A7EE2;
        background-color: #a3cbf3;
        cursor: pointer;
        padding: 6px 10px;
        text-align: center;
        margin-left: 6px;
        margin-top: 6px;
        .manClose {
          position: absolute;
          top: -5px;
          right: -5px;
          width: 12px;
          height: 12px;
        }
      }
    }
    .search_sList {
      background-color: #fff;
      padding: 28px 40px;
      .top_opera {
        display: flex;
        .el-form {
          flex: 4;
          .el-form-item__content {
            width: 218px;
          }
        }
        .btn_area, .btn_search_area {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
    .table_sList {
      box-sizing: border-box;
      padding: 30px 40px;
      margin-top: 10px;
      background-color: #fff;
      .opera_btnArea {
        margin-bottom: 20px;
      }
      ::-webkit-scrollbar {
        width: 8px;
        height: 8px;
        background-color: #fff;
      }
      ::-webkit-scrollbar-thumb {
          -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
          background-color: rgba(0, 0, 0, .1);
          border-radius: 3px;
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
      }
      .el-table__header .has-gutter th.is-leaf:nth-last-child(2) {
          border-right: none;
      }
      .el-dropdown-link {
        cursor: pointer;
        color: #409EFF;
      }
      .el-icon-arrow-down {
        font-size: 12px;
      }
      .el-pagination {
        text-align: right;
      }
    }
    .table_list_new {
      padding: 0;
      .el-pagination {
        text-align: right;
      }
    }
    .course_area {
      padding: 30px;
      box-sizing: border-box;
      .btn_area {
        text-align: right;
      }
    }
    .cc_opera {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      height: 200px;
    }
  }
</style>